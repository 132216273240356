import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from './../components/Layout'
import BlogRoll from './../components/posts/postcontainers/FullPageRollContainer'
import Header from '../components/header/Header'
import ProductList from '../components/ProductList'

export const TheBioBreakTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <Header title={title} subheading={subheading} />
    <BlogRoll />
    <ProductList />
    {/* <div className="hex-overlay">
      <svg xmlns='http://www.w3.org/2000/svg' width="100%" height="100%">
        <defs>
          <pattern id="hex" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
            <rect width='56' height='100' fill='none'/>
            <path d='M28 66L0 50L0 16L28 0L56 16L56 50L28 66L28 100' fill='none' stroke='#fff' stroke-width='2'/>
            <path d='M28 0L28 34L0 50L0 84L28 100L56 84L56 50L28 34' fill='none' stroke='#ffe' stroke-width='2'/>
          </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#hex)"></rect>
      </svg>
    </div> */}
  </div>
)

TheBioBreakTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TheBioBreakTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query TheBioBreakTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "biobreak-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`
